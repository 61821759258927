<template>
    <b-row class="ml-0 mr-0">
        <b-col xl="4" lg="6" sm="12">
            <div class="event-type-selector p-3 m-1" :style="{backgroundColor: colors.evenements.background + '20'}">
                <h4>Événements</h4>
                <toggle-button v-model="toLoad.evenements"
                               :color="colors.evenements.background"
                               :sync="true"/>
            </div>
        </b-col>
        <b-col xl="4" lg="6" sm="12" v-if="isCluji()">
            <div class="event-type-selector p-3 m-1" :style="{backgroundColor: colors.local.background + '20'}">
                <h4>Réservations du local</h4>
                <toggle-button v-model="toLoad.local"
                               :color="colors.local.background"
                               :sync="true"/>
            </div>
        </b-col>
        <b-col xl="4" lg="6" sm="12">
            <div class="event-type-selector p-3 m-1" :style="{backgroundColor: colors.permanences.background + '20'}">
                <h4>Permanences</h4>
                <toggle-button v-model="toLoad.permanences"
                               :color="colors.permanences.background"
                               :sync="true"/>
            </div>
        </b-col>
        <b-col xl="4" lg="6" sm="12">
            <div class="event-type-selector p-3 m-1" :style="{backgroundColor: colors.jeux.background + '20'}">
                <h4>Jeux</h4>
                <multi-select v-model="toLoad.jeux"
                              :options="options.jeux"
                              multiple="true"
                              :close-on-select="false"
                              track-by="id"
                              :clear-on-select="false"
                              :preserve-search="false"
                              label="nom"/>
            </div>
        </b-col>
        <b-col xl="4" lg="6" sm="12">
            <div class="event-type-selector p-3 m-1" :style="{backgroundColor: colors.livres.background + '20'}">
                <h4>Livres</h4>
                <multi-select v-model="toLoad.livres"
                              :options="options.livres"
                              multiple="true"
                              :close-on-select="false"
                              track-by="id"
                              :clear-on-select="false"
                              :preserve-search="false"
                              label="nom"/>
            </div>
        </b-col>
        <b-col xl="4" lg="6" sm="12">
            <div class="event-type-selector p-3 m-1" :style="{backgroundColor: colors.jdrs.background + '20'}">
                <h4>Jeux de rôle</h4>
                <multi-select v-model="toLoad.jdrs"
                              :options="options.jdrs"
                              multiple="true"
                              :close-on-select="false"
                              track-by="id"
                              :clear-on-select="false"
                              :preserve-search="false"
                              label="nom"/>
            </div>
        </b-col>
    </b-row>
</template>

<script>
    import {ToggleButton} from 'vue-js-toggle-button';

    import alert     from '@/util/alert';
    import colors    from '@/util/colors';
    import {apiPath} from '@/util/http';
    import {isCluji} from '@/util/auth';

    const MultiSelect = () => import('@/components/MultiSelect');

    export default {
        name: "CalendarOptionsPicker",
        components: {MultiSelect, ToggleButton},
        props: {
            value: {
                type: Object
            }
        },
        data: () => ({
            colors: colors,
            toLoad: {
                jeux: [],
                livres: [],
                jdrs: [],
                evenements: true,
                local: true,
                permanences: true
            },
            options: {
                jeux: [],
                livres: [],
                jdrs: []
            }
        }),
        methods: {
            isCluji,
            loadJeuxAvailable() {
                alert.loading();

                return this.axios.get(apiPath('list_jeux_cluji_quickview'))
                    .then(response => this.options.jeux = response.data)
                    .finally(alert.stopLoading);
            },
            loadLivresAvailable() {
                alert.loading();

                return this.axios.get(apiPath('list_livres_cluji_quickview'))
                    .then(response => this.options.livres = response.data)
                    .finally(alert.stopLoading);
            },
            loadJdrsAvailable() {
                alert.loading();

                return this.axios.get(apiPath('list_jdrs_cluji_quickview'))
                    .then(response => this.options.jdrs = response.data)
                    .finally(alert.stopLoading);
            },
            loadAvailable() {
                this.loadJeuxAvailable()
                    .then(() => this.loadLivresAvailable())
                    .then(() => this.loadJdrsAvailable())
                    .catch(() => this.$toaster.error("Impossible de récupérer les listes d'objets"));
            }
        },
        watch: {
            value(val) {
                this.toLoad = val;
            },
            toLoad(val) {
                this.toLoad = val;
                this.$emit('input', val);
            }
        },
        mounted() {
            this.toLoad = this.value;
            this.loadAvailable();
        }
    }
</script>

<style scoped>
    .event-type-selector {
        border: 1px solid #e5e5e5;
        border-radius: 0.375rem;
    }
</style>
